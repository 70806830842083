import React, { useEffect, useRef, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line } from 'recharts';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';

const TimeseriesGraph = props => {
    var init = useRef(true);

    const [timeframe, setTimeframe] = useState('1h');
    const [data, setData] = useState([]);

    const axiosProtect = useAxiosProtect();

    useEffect(() => {
        if(!init.current) return;

        let siteID = window.location.href.split('/').slice(-1);
        if(parseInt(siteID) !== 14) return;

        axiosProtect.get(`/asbr/blueroof/timeseries-data?id=${siteID}&timeframe=${timeframe}&fields[]=${'tank_ultrasonic'}`).then(response => {
            setData(response.data.data);

            init.current = false;
        }).catch(error => {
            init.current = false;
        });
    }, []);

    useEffect(() => {
        if(init.current) return;

        let siteID = window.location.href.split('/').slice(-1);
        if(parseInt(siteID) !== 14) return;

        axiosProtect.get(`/asbr/blueroof/timeseries-data?id=${siteID}&timeframe=${timeframe}&fields[]=${'tank_ultrasonic'}`).then(response => {
            setData(response.data.data);

            init.current = false;
        }).catch(error => {
            init.current = false;
        });
    }, [timeframe]);

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (tick / 1e6).toFixed(1) + 'M'; 
        else if (Math.abs(tick) >= 1e5) return (tick / 1e3).toFixed(0) + 'k';
        else return tick.toLocaleString();
    }

    const formatReadings = reading => {
        return [`${(Math.round(reading * 100) / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} cm`, 'Tank level'];
    }

    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
    
        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm} ${month}/${day}`;
    };

    return (
        <div style={{height:'100%', width:'100%'}}>
             <div style={{height:'85%'}}>

                <div style={{width:'100%', height:'100%', margin:'1% auto'}}>

                    {/* <div style={{display:'inline-block'}}>
                        <ToggleButtonGroup
                            value={range}
                            exclusive
                            onChange={handleRange}
                            aria-label="text alignment"
                            size='small'
                        >
                            <ToggleButton value="day" aria-label="left aligned">
                                <p style={{margin:'0'}}>Day</p>
                            </ToggleButton>
                            <ToggleButton value="week" aria-label="centered">
                                <p style={{margin:'0'}}>Week</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div> */}

                    <div style={{textAlign:'center'}}>
                        <ToggleButtonGroup
                            value={timeframe}
                            exclusive
                            onChange={(e,v) => setTimeframe(v)}
                            aria-label="text alignment"
                            size='small'
                        >
                            <ToggleButton value='1h' aria-label="left aligned">
                                <p style={{margin:'0'}}>1 hour</p>
                            </ToggleButton>
                            <ToggleButton value='1d' aria-label="left aligned">
                                <p style={{margin:'0'}}>1 day</p>
                            </ToggleButton>
                            <ToggleButton value='1w' aria-label="left aligned">
                                <p style={{margin:'0'}}>1 week</p>
                            </ToggleButton>
                            <ToggleButton value='1m' aria-label="left aligned">
                                <p style={{margin:'0'}}>1 month</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                     <ResponsiveContainer height='100%' width='100%'>
                        <ComposedChart data={data}>
                            <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} 
                                tickFormatter={formatXAxisTick} interval={Math.ceil(data.length / 7)} textAnchor='start'/>
        
                            <YAxis yAxisId="left" tickLine={false} axisLine={false} tickFormatter={formatYAxisTick} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                            label={{value: 'Tank level (cm)', position: 'outside', offset: 0, angle: -90, dx: -25, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}
                            stroke={`rgb(8,114,182)`} strokeWidth={1.5} dot={false}/>
                            
                            <Tooltip 
                                contentStyle={{
                                    backgroundColor: '#F5F5F5',
                                    color: '#333',
                                    border:'1px solid lightgrey',
                                    fontSize: '0.75em',
                                }}
                                labelFormatter={formatXAxisTick}
                                wrapperStyle={{ outline: "none" }}
                                formatter={formatReadings}
                            />
        
                            <CartesianGrid stroke="#f5f5f5" />
                            <Line yAxisId="left" dataKey='tank_ultrasonic' fill={props.primary} dot={false}/>
                            {/* <Line yAxisId="right" type="monotone" dataKey="cumulative" stroke={props.secondary} dot={false}/> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

export default TimeseriesGraph;