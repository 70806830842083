import React, { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ValveTable from '../tables/ValveTable';
import Autocomplete from '@mui/material/Autocomplete';
import ParameterTable from '../tables/ParameterTable';
import RoofSegmentTable from '../tables/RoofSegmentTable';
import './modalStyle.css'
import TankTable from '../tables/TankTable';

const SettingsModal = props => {
    const floatRegex = /^(-?\d*\.?\d*)?$/;
    const fieldRegex = /^[a-z0-9_]*$/;
    
    var pendingRequest = useRef(false);

    const [tabValue, setTabValue] = useState('info');

    const [addingValve, setAddingValve] = useState(false);
    const [newValveForm, setNewValveForm] = useState({
        name: '',
        nameError: '',
        currentState: 'open'
    });

    const [addingParameter, setAddingParameter] = useState(false);
    const [newParamForm, setNewParamForm] = useState({
        screenName: '',
        screenNameError: '',
        fieldName: '',
        fieldNameError: '',
        sensorType: 'level/tank',
        units: 'cm'
    });

    const [addingRoofSegment, setAddingRoofSegment] = useState(false);
    const [newRoofForm, setNewRoofForm] = useState({
        name: '',
        nameError: '',
        sqft: '',
        sqftError: '',
        maxHeight: '',
        maxHeightError: '',
        drainFlowRate: '',
        drainFlowRateError: '',
        sensorDistance: '',
        sensorDistanceError: '',
        controlValve: null,
        controlValveError: '',
        levelSensor: null,
        levelSensorError: ''
    });

    const [addingTank, setAddingTank] = useState(false);
    const [newTankForm, setNewTankForm] = useState({
        name: '',
        nameError: '',
        tankHeight: '',
        tankHeightError: '',
        topupHeight: '',
        topupHeightError: '',
        cityWaterHeight: '',
        cityWaterHeightError: '',
        levelSensor: null,
        levelSensorError: ''
    });

    const handleTabChange = (e, v) => {
        setAddingValve(false);
        setNewValveForm({
            name: '',
            nameError: '',
            currentState: 'open'
        });
        setAddingParameter(false);
        setNewParamForm({
            screenName: '',
            screenNameError: '',
            fieldName: '',
            fieldNameError: '',
            sensorType: 'level/tank',
            units: 'cm'
        });
        setAddingRoofSegment(false);
        setNewRoofForm({
            name: '',
            nameError: '',
            sqft: '',
            sqftError: '',
            maxHeight: '',
            maxHeightError: '',
            drainFlowRate: '',
            drainFlowRateError: '',
            sensorDistance: '',
            sensorDistanceError: '',
            controlValve: null,
            controlValveError: '',
            levelSensor: null,
            levelSensorError: ''
        });
        setNewTankForm({
            name: '',
            nameError: '',
            tankHeight: '',
            tankHeightError: '',
            topupHeight: '',
            topupHeightError: '',
            cityWaterHeight: '',
            cityWaterHeightError: '',
            levelSensor: null,
            levelSensorError: ''
        });
        setAddingTank(false);
        setTabValue(v);
    }

    return (
        <Modal
            show={props.settingsModalOpen !== undefined}
            onHide={() => props.setSettingsModalOpen(false)}
            backdrop="static"
            keyboard={false}
            centered
            dialogClassName="modal-50w"
        >
            <Modal.Header closeButton>
                <Box sx={{width: 'fit-content', backgroundColor:'rgba(255,255,255,0.4)'}}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary.Mui-selected': {
                                color: 'rgb(0,177,168)'
                            },
                            '& .MuiTab-textColorSecondary': {
                                color: 'rgb(8,114,182)'
                            },
                        }}
                        aria-label="secondary tabs example"
                    >
                        <Tab value="info" label="Info" />
                        <Tab value="valve" label="Valves" />
                        <Tab value="parameters" label="Sensors" />
                        <Tab value="roof segments" label="Segments" />
                        <Tab value="tank" label="Tanks" />
                    </Tabs>
                </Box>
            </Modal.Header>
            <Modal.Body>
                {
                    tabValue === 'info' && 
                    <div>
                        <p><strong>Step 1</strong> Go to the <strong style={{color:'rgb(8,114,182)'}}>valves</strong> tab and add all control valves present in the ASBR system.</p>
                        <p><strong>Step 2</strong> Under the <strong style={{color:'rgb(8,114,182)'}}>sensors</strong> tab, add all temperature and ultrasonic sensors present in the system.</p>
                        <p><strong>Step 3</strong> Under the <strong style={{color:'rgb(8,114,182)'}}>segments</strong> tab, add all roof segments that the ASBR system is comprised of. A 'roof segment' is defined as a section of roof that:</p>
                        <ul>
                            <li><p>Has a dedicated water level sensor to measure the height of water being stored</p></li>
                            <p>and</p>
                            <li><p>Has a dedicated control valve to drain the roof</p></li>
                        </ul>
                        <p>Ensure that all valves and sensors are registered before adding segments. You will be prompted to link one control valve to each segment as its rain top-up valve, and one ultrasonic sensor that acts as its roof water level sensor.</p>
                        <p><strong>Step 4</strong> Go to the <strong style={{color:'rgb(8,114,182)'}}>tanks</strong> tab and define the system's tank height limits.</p>
                    </div>
                }
                {
                    tabValue === 'valve' &&
                    <div>
                        <Button onClick={() => {
                            if(addingValve){
                                setNewValveForm({name: '', nameError: '', currentState: 'open'});
                            }
                            setAddingValve(!addingValve);
                        }}>
                            {addingValve ? 'Cancel' : 'Add valve'}
                        </Button>

                        {addingValve && <Zoom in={addingValve}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Valve name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newValveForm.name}
                                    onChange={e => {
                                        setNewValveForm(prev => ({
                                            ...prev,
                                            name: e.target.value
                                        }));
                                    }}
                                    error={newValveForm.nameError !== ''}
                                    helperText={newValveForm.nameError}
                                />

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: '0', display: 'inline-flex', alignItems: 'center', fontSize:'0.9rem'}}>Valve is currently:</p>
                                </div>

                                <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                                    <Select
                                        value={newValveForm.currentState}
                                        onChange={e => {
                                            setNewValveForm(prev => ({
                                                ...prev,
                                                currentState: e.target.value
                                            }));
                                        }}
                                    >
                                        <MenuItem value='open'>Open</MenuItem>
                                        <MenuItem value='close'>Closed</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button onClick={async () => {
                                    if(pendingRequest.current) return;
                                    pendingRequest.current = true;

                                    let isValid = true;
                                    if(newValveForm.name === ''){
                                        isValid = false;

                                        setNewValveForm(prev => ({
                                            ...prev,
                                            nameError:'Required'
                                        }));

                                        pendingRequest.current = false;
                                    }

                                    if(!isValid) return;

                                    let newValve = await props.addValve(newValveForm);

                                    if(newValve == null){
                                        setNewValveForm(prev => ({
                                            ...prev,
                                            nameError:'Could not add valve'
                                        }));
                                    }else{
                                        setNewValveForm({name: '', nameError: '', currentState: 'open'});
                                        setAddingValve(false);
                                    }

                                    pendingRequest.current = false;
                                }}>Add</Button>
                            </div>
                        </Zoom>}

                        <ValveTable valves={props.valves} formatDate={props.formatDate}/>
                    </div>
                }
                {
                    tabValue === 'parameters' &&
                    <div>
                        <Button onClick={() => {
                            if(addingParameter){
                                setNewParamForm({
                                    screenName: '',
                                    screenNameError: '',
                                    fieldName: '',
                                    fieldNameError: '',
                                    sensorType: 'level/tank',
                                    units: 'cm'
                                });
                            }
                            setAddingParameter(!addingParameter);
                        }}>
                            {addingParameter ? 'Cancel' : 'Add parameter'}
                        </Button>

                        {addingParameter && <Zoom in={addingParameter}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Screen name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newParamForm.screenName}
                                    onChange={e => {
                                        setNewParamForm(prev => ({
                                            ...prev,
                                            screenName: e.target.value
                                        }));
                                    }}
                                    error={newParamForm.screenNameError !== ''}
                                    helperText={newParamForm.screenNameError}
                                />

                                <TextField 
                                    label="Field name (API)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newParamForm.fieldName}
                                    onChange={e => {
                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldName: e.target.value
                                        }));
                                    }}
                                    error={newParamForm.fieldNameError !== ''}
                                    helperText={newParamForm.fieldNameError}
                                />

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: '0', display: 'inline-flex', alignItems: 'center', fontSize:'0.9rem'}}>Sensor type:</p>
                                </div>

                                <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                                    <Select
                                        value={newParamForm.sensorType}
                                        onChange={e => {
                                            setNewParamForm(prev => ({
                                                ...prev,
                                                sensorType: e.target.value,
                                                units: e.target.value.startsWith('level') ? 'cm' : 'degc'
                                            }));
                                        }}
                                    >
                                        <MenuItem value='level/tank'>Level sensor - tank</MenuItem>
                                        <MenuItem value='level/roof'>Level sensor - roof</MenuItem>
                                        <MenuItem value='temperature'>Temperature</MenuItem>
                                    </Select>
                                </FormControl>

                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p style={{margin: '0', display: 'inline-flex', alignItems: 'center', fontSize:'0.9rem'}}>Measured units:</p>
                                </div>

                                <FormControl style={{width:'100%', marginBottom:'1.25vh'}}>
                                    <Select
                                        value={newParamForm.units}
                                        onChange={e => {
                                            setNewParamForm(prev => ({
                                                ...prev,
                                                units: e.target.value
                                            }));
                                        }}
                                    >
                                        {newParamForm.sensorType.startsWith('level') && <MenuItem value='cm'>cm (level)</MenuItem>}
                                        {newParamForm.sensorType === 'temperature' && <MenuItem value='degc'>&deg;C (temperature)</MenuItem>}
                                    </Select>
                                </FormControl>

                                <Button onClick={async () => {
                                     if(pendingRequest.current) return;
                                     pendingRequest.current = true;
 
                                     let isValid = true;
                                     if(newParamForm.screenName === ''){
                                         isValid = false;
 
                                         setNewParamForm(prev => ({
                                             ...prev,
                                             screenNameError:'Required'
                                         }));
                                     }

                                     if(newParamForm.fieldName === ''){
                                        isValid = false;

                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldNameError:'Required'
                                        }));
                                    }else if(!fieldRegex.test(newParamForm.fieldName)){
                                        isValid = false;

                                        setNewParamForm(prev => ({
                                            ...prev,
                                            fieldNameError:'Only lower case letters, numbers, and underscores'
                                        }));
                                    }
 
                                     if(!isValid){
                                        pendingRequest.current = false;
                                        return;
                                     }
 
                                     let newParam = await props.addParameter(newParamForm);
 
                                     if(newParam == null){
                                         setNewParamForm(prev => ({
                                             ...prev,
                                             screenNameError:'Could not add parameter'
                                         }));
                                     }else{
                                         setNewParamForm(
                                            {
                                                screenName: '',
                                                screenNameError: '',
                                                fieldName: '',
                                                fieldNameError: '',
                                                sensorType: 'level/tank',
                                                units: 'cm'
                                            }
                                         );
                                         setAddingParameter(false);
                                     }
 
                                     pendingRequest.current = false;
                                }}>
                                    Add
                                </Button>
                            </div>
                        </Zoom>}

                        <ParameterTable parameters={props.parameters} formatDate={props.formatDate}/>
                    </div>
                }
                {
                    tabValue === 'roof segments' &&
                    <div>
                        <Button onClick={() => {
                            if(addingRoofSegment){
                                setNewRoofForm({
                                    name: '',
                                    nameError: '',
                                    sqft: '',
                                    sqftError: '',
                                    maxHeight: '',
                                    maxHeightError: '',
                                    drainFlowRate: '',
                                    drainFlowRateError: '',
                                    sensorDistance: '',
                                    sensorDistanceError: '',
                                    controlValve: null,
                                    controlValveError: '',
                                    levelSensor: null,
                                    levelSensorError: ''
                                });
                            }
                            setAddingRoofSegment(!addingRoofSegment);
                        }}>
                            {addingRoofSegment ? 'Cancel' : 'Add segment'}
                        </Button>

                        {addingRoofSegment && <Zoom in={addingRoofSegment}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Roof segment name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newRoofForm.name}
                                    onChange={e => {
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            name: e.target.value
                                        }));
                                    }}
                                    error={newRoofForm.nameError !== ''}
                                    helperText={newRoofForm.nameError}
                                />

                                <TextField 
                                    label="Area (sqft)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newRoofForm.sqft}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            sqft: e.target.value
                                        }));
                                    }}
                                    error={newRoofForm.sqftError !== ''}
                                    helperText={newRoofForm.sqftError}
                                />

                                <TextField 
                                    label="Drain flow rate (LPM)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newRoofForm.drainFlowRate}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            drainFlowRate: e.target.value
                                        }));
                                    }}
                                    error={newRoofForm.drainFlowRateError !== ''}
                                    helperText={newRoofForm.drainFlowRateError}
                                />

                                <TextField 
                                    label="Max water height (cm)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newRoofForm.maxHeight}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            maxHeight: e.target.value
                                        }));
                                    }}
                                    error={newRoofForm.maxHeightError !== ''}
                                    helperText={newRoofForm.maxHeightError}
                                />

                                <TextField 
                                    label="Sensor distance (cm)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newRoofForm.sensorDistance}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            sensorDistance: e.target.value
                                        }));
                                    }}
                                    error={newRoofForm.sensorDistanceError !== ''}
                                    helperText={newRoofForm.sensorDistanceError}
                                />

                                <>
                                    <Autocomplete
                                        style={{width:'100%', margin:'1.25vh 0'}}
                                        options={props.valves}
                                        value={newRoofForm.controlValve}
                                        getOptionLabel={option => `${option.valve_name}`}
                                        onChange={(_, option) => {
                                            setNewRoofForm(prev => ({
                                                ...prev,
                                                controlValve: option
                                            }));
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                <span>{`${option.valve_name}`}</span>
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Control valve" />}
                                    />
                                    {newRoofForm.controlValveError !== '' && <small style={{color:'red'}}>{newRoofForm.controlValveError}</small>}
                                </>

                                <>
                                    <Autocomplete
                                        style={{width:'100%', margin:'2vh 0 1.25vh 0'}}
                                        options={props.parameters.filter(param => param.sensor_type === 'level/roof')}
                                        value={newRoofForm.levelSensor}
                                        getOptionLabel={option => `${option.screen_name}`}
                                        onChange={(_, option) => {
                                            setNewRoofForm(prev => ({
                                                ...prev,
                                                levelSensor: option
                                            }));
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                <span>{`${option.screen_name}`}</span>
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Level sensor" />}
                                    />
                                    {newRoofForm.levelSensorError !== '' && <small style={{color:'red'}}>{newRoofForm.levelSensorError}</small>}
                                    <br/>
                                </>

                                <Button onClick={async () => {
                                    if(pendingRequest.current) return;
                                    pendingRequest.current = true;

                                    let isValid = true;
                                    let roofFormCopy = {...newRoofForm};

                                    if(newRoofForm.name.trim() === ''){
                                        isValid = false;
                                        roofFormCopy.nameError = 'Required';
                                    }else roofFormCopy.nameError = '';

                                    if(isNaN(parseFloat(newRoofForm.sqft))){
                                        isValid = false;
                                        roofFormCopy.sqftError = 'Required';
                                    }else if(parseFloat(newRoofForm.sqft) <= 0){
                                        isValid = false;
                                        roofFormCopy.sqftError = 'Area must be > 0 sqft';
                                    }else roofFormCopy.sqftError = '';

                                    if(isNaN(parseFloat(newRoofForm.maxHeight))){
                                        isValid = false;
                                        roofFormCopy.maxHeightError = 'Required';
                                    }else if(parseFloat(newRoofForm.maxHeight) <= 0){
                                        isValid = false;
                                        roofFormCopy.maxHeightError = 'Max water level must be > 0 cm';
                                    }else roofFormCopy.maxHeightError = '';

                                    if(isNaN(parseFloat(newRoofForm.drainFlowRate))){
                                        isValid = false;
                                        roofFormCopy.drainFlowRateError = 'Required';
                                    }else if(parseFloat(newRoofForm.drainFlowRate) <= 0){
                                        isValid = false;
                                        roofFormCopy.drainFlowRateError = 'Drain flow rate must be > 0 LPM';
                                    }else roofFormCopy.drainFlowRateError = '';

                                    if(isNaN(parseFloat(newRoofForm.sensorDistance))){
                                        isValid = false;
                                        roofFormCopy.sensorDistanceError = 'Required';
                                    }else if(parseFloat(newRoofForm.sensorDistance) <= 0){
                                        isValid = false;
                                        roofFormCopy.sensorDistanceError = 'Sensor distance must be > 0 cm';
                                    }
                                    else if(parseFloat(newRoofForm.sensorDistance) <= parseFloat(newRoofForm.maxHeight)){
                                        isValid = false;
                                        roofFormCopy.sensorDistanceError = 'Sensor distance must be greater than max water height';
                                    }else roofFormCopy.sensorDistanceError = '';

                                    if(newRoofForm.controlValve == null){
                                        isValid = false;
                                        roofFormCopy.controlValveError = 'Required';
                                    }else roofFormCopy.controlValveError = '';

                                    if(newRoofForm.levelSensor == null){
                                        isValid = false;
                                        roofFormCopy.levelSensorError = 'Required';
                                    }else roofFormCopy.levelSensorError = '';

                                    setNewRoofForm(roofFormCopy);

                                    if(!isValid){
                                        pendingRequest.current = false;
                                        return;
                                    }

                                    let newSegment = await props.addRoofSegment(newRoofForm);

                                    if(newSegment == null){
                                        setNewRoofForm(prev => ({
                                            ...prev,
                                            nameError:'Could not add segment'
                                        }));
                                    }else{
                                        setNewRoofForm({
                                            name: '',
                                            nameError: '',
                                            sqft: '',
                                            sqftError: '',
                                            maxHeight: '',
                                            maxHeightError: '',
                                            drainFlowRate: '',
                                            drainFlowRateError: '',
                                            sensorDistance: '',
                                            sensorDistanceError: '',
                                            controlValve: null,
                                            controlValveError: '',
                                            levelSensor: null,
                                            levelSensorError: ''
                                        });
                                        setAddingRoofSegment(false);
                                    }

                                    pendingRequest.current = false;
                                }}>Add</Button>
                            </div>
                        </Zoom>}

                        <RoofSegmentTable segments={props.roofSegments}/>
                    </div>
                }
                 {
                    tabValue === 'tank' &&
                    <div>
                        {props.tanks.length === 0 && <Button onClick={() => {
                            if(addingTank){
                                setNewTankForm({
                                    name: '',
                                    nameError: '',
                                    tankHeight: '',
                                    tankHeightError: '',
                                    topupHeight: '',
                                    topupHeightError: '',
                                    cityWaterHeight: '',
                                    cityWaterHeightError: '',
                                    levelSensor: null,
                                    levelSensorError: ''
                                });
                            }
                            setAddingTank(!addingTank);
                        }}>
                            {addingTank ? 'Cancel' : 'Add tank'}
                        </Button>}

                        {addingTank && <Zoom in={addingTank}>
                            <div style={{backgroundColor:'#f5f5f5', width:'100%', height:'fit-content', padding:'1%'}}>
                                <TextField 
                                    label="Name" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newTankForm.name}
                                    onChange={e => {
                                        setNewTankForm(prev => ({
                                            ...prev,
                                            name: e.target.value
                                        }));
                                    }}
                                    error={newTankForm.nameError !== ''}
                                    helperText={newTankForm.nameError}
                                />

                                <TextField 
                                    label="Tank height (from sensor to bottom of tank - cm)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newTankForm.tankHeight}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewTankForm(prev => ({
                                            ...prev,
                                            tankHeight: e.target.value
                                        }));
                                    }}
                                    error={newTankForm.tankHeightError !== ''}
                                    helperText={newTankForm.tankHeightError}
                                />

                                <TextField 
                                    label="Top-up height (from bottom of tank to threshold - cm)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newTankForm.topupHeight}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewTankForm(prev => ({
                                            ...prev,
                                            topupHeight: e.target.value
                                        }));
                                    }}
                                    error={newTankForm.topupHeightError !== ''}
                                    helperText={newTankForm.topupHeightError}
                                />

                                <TextField 
                                    label="City water height (from bottom of tank to threshold - cm)" 
                                    variant="outlined"
                                    style={{width:'100%', margin:'1.25vh 0'}}
                                    value={newTankForm.cityWaterHeight}
                                    onChange={e => {
                                        if(!floatRegex.test(e.target.value)) return;
                                        setNewTankForm(prev => ({
                                            ...prev,
                                            cityWaterHeight: e.target.value
                                        }));
                                    }}
                                    error={newTankForm.cityWaterHeightError !== ''}
                                    helperText={newTankForm.cityWaterHeightError}
                                />

                                <>
                                    <Autocomplete
                                        style={{width:'100%', margin:'1.75vh 0 1.25vh 0'}}
                                        options={props.parameters.filter(param => param.sensor_type === 'level/tank')}
                                        value={newTankForm.levelSensor}
                                        getOptionLabel={option => `${option.screen_name}`}
                                        onChange={(_, option) => {
                                            setNewTankForm(prev => ({
                                                ...prev,
                                                levelSensor: option
                                            }));
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                <span>{`${option.screen_name}`}</span>
                                            </Box>
                                        )}
                                        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Level sensor" />}
                                    />
                                    {newTankForm.levelSensorError !== '' && <small style={{color:'red'}}>{newTankForm.levelSensorError}</small>}
                                    <br/>
                                </>

                                <Button onClick={async () => {
                                    if(pendingRequest.current) return;
                                    pendingRequest.current = true;

                                    let isValid = true;
                                    let tankFormCopy = {...newTankForm};

                                    if(newTankForm.name.trim() === ''){
                                        isValid = false;
                                        tankFormCopy.nameError = 'Required';
                                    }else tankFormCopy.nameError = '';

                                    if(isNaN(parseFloat(newTankForm.tankHeight))){
                                        isValid = false;
                                        tankFormCopy.tankHeightError = 'Required';
                                    }else if(parseFloat(newTankForm.tankHeight) <= 0){
                                        isValid = false;
                                        tankFormCopy.tankHeightError = 'Tank height must be > 0 cm';
                                    }else tankFormCopy.tankHeightError = '';

                                    if(isNaN(parseFloat(newTankForm.topupHeight))){
                                        isValid = false;
                                        tankFormCopy.topupHeightError = 'Required';
                                    }else if(parseFloat(newTankForm.topupHeight) <= 0){
                                        isValid = false;
                                        tankFormCopy.topupHeightError = 'Top-up height must be > 0 cm';
                                    }else tankFormCopy.topupHeightError = '';

                                    if(isNaN(parseFloat(newTankForm.cityWaterHeight))){
                                        isValid = false;
                                        tankFormCopy.cityWaterHeightError = 'Required';
                                    }else if(parseFloat(newTankForm.cityWaterHeight) <= 0){
                                        isValid = false;
                                        tankFormCopy.cityWaterHeightError = 'City water height must be > 0 cm';
                                    }else tankFormCopy.cityWaterHeightError = '';

                                    if(newTankForm.levelSensor == null){
                                        isValid = false;
                                        tankFormCopy.levelSensorError = 'Required';
                                    }else tankFormCopy.levelSensorError = '';

                                    if(isValid){
                                        let validHeightOrder = parseFloat(newTankForm.tankHeight) > parseFloat(newTankForm.topupHeight) && parseFloat(newTankForm.topupHeight) > parseFloat(newTankForm.cityWaterHeight);
                                        if(!validHeightOrder){
                                            isValid = false;
                                            tankFormCopy.tankHeightError = 'Requirement: tank height > top-up height > city water height';
                                        }else tankFormCopy.tankHeightError = '';
                                    }

                                    setNewTankForm(tankFormCopy);

                                    if(!isValid){
                                        pendingRequest.current = false;
                                        return;
                                    }

                                    let newTank = await props.addTank(newTankForm);

                                    if(newTank == null){
                                        setNewTankForm(prev => ({
                                            ...prev,
                                            nameError:'Could not add tank'
                                        }));
                                    }else{
                                        setNewTankForm({
                                            name: '',
                                            nameError: '',
                                            tankHeight: '',
                                            tankHeightError: '',
                                            topupHeight: '',
                                            topupHeightError: '',
                                            cityWaterHeight: '',
                                            cityWaterHeightError: '',
                                            levelSensor: null,
                                            levelSensorError: ''
                                        });
                                        setAddingTank(false);
                                    }

                                    pendingRequest.current = false;
                                }}>Add</Button>
                            </div>
                        </Zoom>}

                        <TankTable tanks={props.tanks}/>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
}

export default SettingsModal;