import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientDashContainer from './client_dashboard/ClientDashContainer';
import StaffDashContainer from './staff_dashboard/StaffDashContainer';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import APIDashboardContainer from './api_dashbaord/APIDashboardContainer';
import HMartContainer from '../hmart/HMartContainer';

const DashboardRouter = props => {
    const navigate = useNavigate();
    const [dashToRender, setDashToRender] = useState(null);
    const axiosProtect = useAxiosProtect();

    useEffect(() => {
        const evaluateDashboardNavigation= async () => {
            if(props.auth?.role === process.env.REACT_APP_CLIENT_ADMIN || props.auth?.role === process.env.REACT_APP_CLIENT_STAFF){
                let redirectData = await axiosProtect.get(`/auth/query/client-site-access?user_id=${props.auth?.id}&role=${props.auth?.role}&org_id=${props.auth?.org_id}`);

                if(redirectData.data.redirectData.redirect){
                    navigate(`/site/${redirectData.data.redirectData.siteToRedirect}`, {replace: true});

                }else{
                    setDashToRender('client');
                }
            }else if(props.auth?.role === process.env.REACT_APP_ES_ADMIN || props.auth?.role === process.env.REACT_APP_ES_USER){
                setDashToRender('staff');
            }else if(props.auth?.role === process.env.REACT_APP_CARBON_ONE_USER){
                setDashToRender('carbonone');
            }else if(props.auth?.role === process.env.REACT_APP_HMART_USER){
                setDashToRender('hmart');
            }else{
                navigate('/', {replace: true});
            }
        }

        evaluateDashboardNavigation();
    }, [props.auth, navigate]);

    if(dashToRender === 'client') return <ClientDashContainer isPortrait={props.isPortrait}/>
    else if(dashToRender === 'staff') return <StaffDashContainer isPortrait={props.isPortrait}/>
    else if(dashToRender === 'carbonone') return <APIDashboardContainer isPortrait={props.isPortrait}/>
    else if(dashToRender === 'hmart') return <HMartContainer isPortrait={props.isPortrait}/>
    else return <div></div>
}

export default DashboardRouter;