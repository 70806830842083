import React, { useEffect, useRef, useState } from 'react';
import ParameterTable from './tables/ParameterTable';
import ValveTable from './tables/ValveTable';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsModal from './modals/SettingsModal';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import ASBRAlertLabsModal from './modals/ASBRAlertLabsModal';
import ControlsModal from './modals/ControlsModal';
import TimeseriesGraph from './graphs/TimeseriesGraph';

const BlueRoofSitePage = props => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var init = useRef(true);

    const [anchor, setAnchor] = useState(null);
    const menuOpen = Boolean(anchor);

    const handleMenuClick = e => {
        setAnchor(e.currentTarget);
    }

    const handleMenuClose = () => {
        setAnchor(null);
    }

    const [valves, setValves] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [roofSegments, setRoofSegments] = useState([]);
    const [sensors, setSensors] = useState([]);
    const [tanks, setTanks] = useState([]);

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [controlsModalOpen, setControlsModalOpen] = useState(false);
    const [alertlabsModalOpen, setAlertlabsModalOpen] = useState(false);

    const axiosProtect = useAxiosProtect();

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        axiosProtect.get(`/asbr/blueroof/site-data?site_id=${window.location.href.split('/').slice(-1)[0]}`).then(response => {
            setValves(response.data.asbrData.valves == null ? [] : response.data.asbrData.valves.sort((a, b) => a.valve_name.localeCompare(b.valve_name)));
            setParameters(response.data.asbrData.parameters == null ? [] : response.data.asbrData.parameters.sort((a, b) => a.screen_name.localeCompare(b.screen_name)));
            setRoofSegments(response.data.asbrData.segments == null ? [] : response.data.asbrData.segments.sort((a, b) => a.name.localeCompare(b.name)));
            setSensors(response.data.asbrData.sensors == null ? [] : response.data.asbrData.sensors.sort((a, b) => a.name.localeCompare(b.name)));
            setTanks(response.data.asbrData.tanks == null ? [] : response.data.asbrData.tanks.sort((a, b) => a.name.localeCompare(b.name)));
        }).catch(err => {});

    }, []);

    const addValve = async valve => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/valve', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                valve_name: valve.name,
                is_open: valve.currentState === 'open'
            });
            
            let allValves = [...valves, response.data.newValve];
            allValves.sort((a, b) => a.valve_name.localeCompare(b.valve_name));
            setValves(allValves);

            return response.data.newValve;
        }catch(err){
            return null;
        }
    }

    const deleteValve = async valve => {

    }

    const addParameter = async param => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/field', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                screen_name: param.screenName,
                field_name: param.fieldName,
                units: param.units
            });
            
            let allParams = [...parameters, response.data.newField];
            allParams.sort((a, b) => a.screen_name.localeCompare(b.screen_name));
            setParameters(allParams);

            return response.data.newField;
        }catch(err){
            return null;
        }
    }

    const deleteParameter = async param => {

    }

    const addRoofSegment = async segment => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/segment', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                topup_valve_id: parseInt(segment.controlValve.id),
                area_sqft: parseFloat(segment.sqft),
                max_height_cm: parseFloat(segment.maxHeight),
                drain_flow_rate: parseFloat(segment.drainFlowRate),
                sensor_distance_cm: parseFloat(segment.sensorDistance),
                name: segment.name.trim(),
                level_sensor_id: parseInt(segment.levelSensor.id)
            });
            
            let allSegments = [...roofSegments, response.data.newSegment];
            allSegments.sort((a, b) => a.name.localeCompare(b.name));
            setRoofSegments(allSegments);

            return response.data.newSegment;
        }catch(err){
            return null;
        }
    }

    const addAlertLabsSensor = async sensor => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/alertlabs-device', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                device_type: sensor.deviceType,
                name: sensor.name.trim(),
                alertlabs_device_name: sensor.deviceName.trim(),
                alertlabs_location_name: sensor.locationName.trim(),
            });
            
            let allSensors = [...sensors, response.data.newSensor];
            allSensors.sort((a, b) => a.name.localeCompare(b.name));
            setSensors(allSensors);

            return response.data.newSensor;
        }catch(err){
            return null;
        }
    }

    const toggleAlertlabsSensor = async sensorID => {
        try{
            let response = await axiosProtect.put(`/asbr/blueroof/alertlabs-toggle-status?sensorID=${sensorID}`);

            let sensorCopy = [...sensors];
            for(let i = 0;i<sensorCopy.length;++i){
                if(sensorCopy[i].id === response.data.toggledSensor.id){
                    sensorCopy[i] = response.data.toggledSensor;
                    break;
                }
            }

            setSensors(sensorCopy);
        }catch(err){
            return null;
        }
    }

    const addTank = async tank => {
        try{
            let response = await axiosProtect.post('/asbr/blueroof/tank', {
                site_id: parseInt(window.location.href.split('/').slice(-1)[0]),
                tank_height_cm: parseFloat(tank.tankHeight),
                topup_height_cm: parseFloat(tank.topupHeight),
                city_water_height_cm: parseFloat(tank.cityWaterHeight),
                name: tank.name.trim(),
                level_sensor_id: parseInt(tank.levelSensor.id)
            });
            
            let allTanks = [...tanks, response.data.newTank];
            allTanks.sort((a, b) => a.name.localeCompare(b.name));
            setTanks(allTanks);

            return response.data.newTank;
        }catch(err){
            return null;
        }
    }

    const formatDate = dateString => {
        const date = new Date(dateString);

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        const month = monthNames[monthIndex];
        return `${hours}:${minutes} ${day} ${month} ${year}`;
    }

    return (
        <div style={{height:'100%', width:'100%'}}>
            {
                settingsModalOpen && 
                <SettingsModal 
                    settingsModalOpen={settingsModalOpen} setSettingsModalOpen={setSettingsModalOpen}
                    valves={valves} parameters={parameters} roofSegments={roofSegments} tanks={tanks}
                    addValve={addValve} deleteValve={deleteValve}
                    addParameter={addParameter} deleteParameter={deleteParameter}
                    addRoofSegment={addRoofSegment}
                    addTank={addTank}
                    formatDate={formatDate}
                />
            }

            {
                alertlabsModalOpen &&
                <ASBRAlertLabsModal
                    alertlabsModalOpen={alertlabsModalOpen} setAlertlabsModalOpen={setAlertlabsModalOpen}
                    sensors={sensors} addAlertLabsSensor={addAlertLabsSensor} formatDate={formatDate}
                    toggleAlertlabsSensor={toggleAlertlabsSensor}
                />
            }

            {
                controlsModalOpen &&
                <ControlsModal
                    controlsModalOpen={controlsModalOpen} setControlsModalOpen={setControlsModalOpen}
                    sensors={sensors} addAlertLabsSensor={addAlertLabsSensor} formatDate={formatDate}
                    toggleAlertlabsSensor={toggleAlertlabsSensor}
                />
            }

            <div style={{height:'4%', display:'flex', flexDirection:'row'}}>
                <Button
                    id="basic-button"
                    aria-controls={menuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={handleMenuClick}
                >
                    Configuration
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchor}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={() => {setSettingsModalOpen(true); handleMenuClose()}}>Hardware</MenuItem>
                    <MenuItem onClick={() => {setControlsModalOpen(true); handleMenuClose()}}>Controls</MenuItem>
                    <MenuItem onClick={() => {setAlertlabsModalOpen(true); handleMenuClose()}}>AlertLabs</MenuItem>
                </Menu>
            </div>

            <div style={{display:'flex', flexDirection:'row', height:'47%'}}>
                <div style={{flex:'3.5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
                    Schematic coming soon
                </div>
                
                <div style={{width:'1vw'}}></div>
                
                <div style={{flex:'5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
                    <TimeseriesGraph/>
                </div>
            </div>

            <div style={{height:'2%'}}></div>

            <div style={{display:'flex', flexDirection:'row', height:'47%'}}>
                <div style={{flex:'3.5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)', overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                    <ValveTable valves={valves} formatDate={formatDate}/>
                </div>

                <div style={{width:'1vw'}}></div>

                <div style={{flex:'5', backgroundColor:'white', borderRadius:'10px', boxShadow:'2px 6px 12px rgb(164, 164, 164)', overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                    <ParameterTable parameters={parameters} formatDate={formatDate}/>
                </div>
            </div>
        </div>
    );
}

export default BlueRoofSitePage;