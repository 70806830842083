import React, { useEffect, useRef, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import GradingIcon from '@mui/icons-material/Grading';
import HelpCenter from '@mui/icons-material/HelpCenter';
import useAxiosProtect from '../../hooks/useAxiosProtect';

const CreateOppModal = props => {
    const axiosProtect = useAxiosProtect();

    const floatRegex = /^(-?\d*\.?\d*)?$/;
    
    var init = useRef(true);

    const categoryTip = 'Metric that the opportunity primarily conserves. You can still add savings for other metrics.';
    const costTip = 'If N/A or unknown, enter 0.';

    const [page, setPage] = useState(1);

    // const [initPageForm, setInitPageForm] = useState({
    //     title: '',
    //     titleMaxLength: 64,
    //     category: '',
    //     projectLead: null,
    //     projectLeaders: [],
    //     startDate: null,
    //     startDateTBD: false,
    //     endDate: null,
    //     endDateTBD: false,
    //     projectCost: '',
    //     errors:{
    //         titleError: '',
    //         categoryError: '',
    //         projectLeadError: '',
    //         projectCostError: '',
    //         dateError: ''
    //     }
    // });

    const [initPageForm, setInitPageForm] = useState({
        title: props.createModalState === 'create' ? '' : props.oppToEdit?.name,
        titleMaxLength: 64,
        category: props.createModalState === 'create' ? '' : props.oppToEdit?.category.toLowerCase(),
        projectLead: null,
        projectLeaders: [],
        startDate: props.createModalState === 'create' ? null : props.oppToEdit.start === 'TBD' ? null : dayjs(parseInt(props.oppToEdit.start)),
        startDateTBD: props.createModalState === 'create' ? false : props.oppToEdit.start === 'TBD',
        endDate: props.createModalState === 'create' ? null : props.oppToEdit.end === 'TBD' ? null : dayjs(parseInt(props.oppToEdit.end)),
        endDateTBD: props.createModalState === 'create' ? false : props.oppToEdit.end === 'TBD',
        projectCost: props.createModalState === 'create' ? '' : props.oppToEdit.cost,
        errors:{
            titleError: '',
            categoryError: '',
            projectLeadError: '',
            projectCostError: '',
            dateError: ''
        }
    });

    const [gasTypes, setGasTypes] = useState([]);

    // const [savingsForm, setSavingsForm] = useState({
    //     water:{
    //         included: false,
    //         utilitySavings: '',
    //         utilitySavingsError: '',
    //         unitCost: '',
    //         unitCostInherit: false,
    //         unitCostError: '',
    //         ghgFactor: '',
    //         ghgFactorInherit: false,
    //         ghgFactorError: ''
    //     },
    //     electricity:{
    //         included: false,
    //         utilitySavings: '',
    //         utilitySavingsError: '',
    //         unitCost: '',
    //         unitCostInherit: false,
    //         unitCostError: '',
    //         ghgFactor: '',
    //         ghgFactorInherit: false,
    //         ghgFactorError: ''
    //     },
    //     gas: {
    //         included: false,
    //         utilitySavings: '',
    //         utilitySavingsError: '',
    //         unitCost: '',
    //         unitCostInherit: false,
    //         unitCostError: '',
    //         ghgFactor: '',
    //         ghgFactorInherit: false,
    //         ghgFactorError: '',
    //         gasType: null,
    //         gasTypeError: '',
    //     }
    // });

    const [savingsForm, setSavingsForm] = useState({
        water:{
            included: props.createModalState === 'create' ? false : props.oppToEdit.waterData.included,
            utilitySavings: props.createModalState === 'create' ? '' : props.oppToEdit.waterData.included ? props.oppToEdit.waterData.utilitySavings : '',
            utilitySavingsError: '',
            unitCost: props.createModalState === 'create' ? '' : props.oppToEdit.waterData.included ? props.oppToEdit.waterData.unitCost : '',
            unitCostInherit: false,
            unitCostError: '',
            ghgFactor: props.createModalState === 'create' ? '' : props.oppToEdit.waterData.included ? props.oppToEdit.waterData.ghgFactor : '',
            ghgFactorInherit: false,
            ghgFactorError: ''
        },
        electricity:{
            included: props.createModalState === 'create' ? false : props.oppToEdit.elecData.included,
            utilitySavings: props.createModalState === 'create' ? '' : props.oppToEdit.elecData.included ? props.oppToEdit.elecData.utilitySavings : '',
            utilitySavingsError: '',
            unitCost: props.createModalState === 'create' ? '' : props.oppToEdit.elecData.included ? props.oppToEdit.elecData.unitCost : '',
            unitCostInherit: false,
            unitCostError: '',
            ghgFactor: props.createModalState === 'create' ? '' : props.oppToEdit.elecData.included ? props.oppToEdit.elecData.ghgFactor : '',
            ghgFactorInherit: false,
            ghgFactorError: ''
        },
        gas: {
            included: props.createModalState === 'create' ? false : props.oppToEdit.gasData.included,
            utilitySavings: props.createModalState === 'create' ? '' : props.oppToEdit.gasData.included ? props.oppToEdit.gasData.utilitySavings : '',
            utilitySavingsError: '',
            unitCost: props.createModalState === 'create' ? '' : props.oppToEdit.gasData.included ? props.oppToEdit.gasData.unitCost : '',
            unitCostInherit: false,
            unitCostError: '',
            ghgFactor: props.createModalState === 'create' ? '' : props.oppToEdit.gasData.included ? props.oppToEdit.gasData.ghgFactor : '',
            ghgFactorInherit: false,
            ghgFactorError: '',
            gasType: null,
            gasTypeError: '',
        }
    });

    const [submissionError, setSubmissionError] = useState('');

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get('/auth/query/es-users').then(response => {
                if(response.status === 200){
                    if(props.createModalState === 'edit'){
                        let manager = null;

                        for(let i = 0;i<response.data.length;++i){
                            if(response.data[i]._id === props.oppToEdit.manager_id){
                                manager = response.data[i];
                                break;
                            }
                        }

                        setInitPageForm({
                            ...initPageForm,
                            projectLead: manager,
                            projectLeaders: response.data
                        });
                    }else{
                        setInitPageForm({
                            ...initPageForm,
                            projectLeaders: response.data
                        });
                    }
                }
            });

            axiosProtect.get('/gsr/gas-sensors/get-gas-types').then(response => {
                setGasTypes([...response.data.gas_types]);

                // if(props.createModalState === 'edit'){
                //     for(let i = 0;i<response.data.gas_types.length;++i){
                //         if(savingsForm.gas.gasType === 'natural gas' && response.data.gas_types[i].gas_type === 'ng'){
                //             setSavingsForm(prev => ({
                //                 ...prev,
                //                 gas: {
                //                     ...prev.gas,
                //                     gasType: response.data.gas_types[i],
                //                 }
                //             }));

                //             break;
                //         }
                //     }
                // }
            });
        }

    }, []);

    const validateInitPage = () => {
        let isValid = true;
        let formValues = {...initPageForm};

        if(formValues.title === ''){
            formValues.errors.titleError = 'Required';
            isValid = false;
        }else{
            formValues.errors.titleError = '';
        }

        if(formValues.category === ''){
            formValues.errors.categoryError = 'Required';
            isValid = false;
        }else{
            formValues.errors.categoryError = '';
        }

        if(!formValues.projectLead){
            formValues.errors.projectLeadError = 'Required';
            isValid = false;
        }else{
            formValues.errors.projectLeadError = '';
        }

        if(formValues.projectCost === ''){
            formValues.errors.projectCostError = 'Required';
            isValid = false;
        }else{
            formValues.errors.projectCostError = '';
        }

        if(!formValues.startDate && !formValues.endDate){
            if(!formValues.startDateTBD || !formValues.endDateTBD){
                formValues.errors.dateError = 'Missing date. Set TBD if unknown.';
                isValid = false;
            }else{
                formValues.errors.dateError = '';
            }

        }else if(formValues.startDate != null && formValues.endDate != null){
            let startMS = new Date(formValues.startDate['$d']).getTime();
            let endMS = new Date(formValues.endDate['$d']).getTime();

            if(endMS - startMS <= 0){
                formValues.errors.dateError = 'Project end date must be later than start.';
                isValid = false;
            }else{
                formValues.errors.dateError = '';
            }
        }else{
            if((!formValues.startDate && !formValues.startDateTBD) || (!formValues.endDate && !formValues.endDateTBD)){
                formValues.errors.dateError = 'Missing date. Set TBD if unknown.';
                isValid = false;
            }else{
                formValues.errors.dateError = '';
            }
        }

        setInitPageForm(formValues);
        return isValid;
    }

    const validateSavingsPage = () => {
        let isValid = true;

        let savingsFormCopy = {...savingsForm};

        if(savingsFormCopy.water.included){
            if(savingsFormCopy.water.utilitySavings === ''){
                savingsFormCopy.water.utilitySavingsError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.water.utilitySavingsError = '';
            }

            if(savingsFormCopy.water.unitCost === ''){
                savingsFormCopy.water.unitCostError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.water.unitCostError = '';
            }

            if(savingsFormCopy.water.ghgFactor === ''){
                savingsFormCopy.water.ghgFactorError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.water.ghgFactorError = '';
            }

        }else{
            savingsFormCopy.water.utilitySavingsError = '';
            savingsFormCopy.water.unitCostError = '';
            savingsFormCopy.water.ghgFactorError = '';
        }

        if(savingsFormCopy.electricity.included){
            if(savingsFormCopy.electricity.utilitySavings === ''){
                savingsFormCopy.electricity.utilitySavingsError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.electricity.utilitySavingsError = '';
            }

            if(savingsFormCopy.electricity.unitCost === ''){
                savingsFormCopy.electricity.unitCostError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.electricity.unitCostError = '';
            }

            if(savingsFormCopy.electricity.ghgFactor === ''){
                savingsFormCopy.electricity.ghgFactorError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.electricity.ghgFactorError = '';
            }

        }else{
            savingsFormCopy.electricity.utilitySavingsError = '';
            savingsFormCopy.electricity.unitCostError = '';
            savingsFormCopy.electricity.ghgFactorError = '';
        }

        if(savingsFormCopy.gas.included){
            if(savingsFormCopy.gas.utilitySavings === ''){
                savingsFormCopy.gas.utilitySavingsError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.gas.utilitySavingsError = '';
            }

            if(savingsFormCopy.gas.unitCost === ''){
                savingsFormCopy.gas.unitCostError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.gas.unitCostError = '';
            }

            if(savingsFormCopy.gas.ghgFactor === ''){
                savingsFormCopy.gas.ghgFactorError = 'Required';
                isValid = false;
            }else{
                savingsFormCopy.gas.ghgFactorError = '';
            }

            if(!savingsFormCopy.gas.gasType){
                savingsFormCopy.gas.gasTypeError= 'Required';
                isValid = false;
            }else{
                savingsFormCopy.gas.gasTypeError = '';
            }

        }else{
            savingsFormCopy.gas.utilitySavingsError = '';
            savingsFormCopy.gas.unitCostError = '';
            savingsFormCopy.gas.ghgFactorError = '';
            savingsFormCopy.gas.gasTypeError = '';
        }

        setSavingsForm(savingsFormCopy);

        return isValid;
    }

    const submitOpportunity = async () => {
        let siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

        let savings =(savingsForm.water.included ? parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.unitCost) : 0) + 
            (savingsForm.electricity.included ? parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.unitCost) : 0) + 
            (savingsForm.gas.included ? parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.unitCost) : 0);
        
        let payback_years = savings === 0 ? 0 : parseFloat(initPageForm.projectCost) / savings;

        let payload = {
            site_id: parseInt(siteID),
            payback_years,
            //init form
            title: initPageForm.title,
            category: initPageForm.category,
            project_lead_user_id: initPageForm.projectLead._id,
            project_cost: parseFloat(initPageForm.projectCost),
            start_date: initPageForm.startDateTBD ? null : new Date(initPageForm.startDate['$d']).getTime(), //FIX
            end_date: initPageForm.endDateTBD ? null : new Date(initPageForm.endDate['$d']).getTime(), //FIX
            //savings form
            has_water: savingsForm.water.included,
            water_savings_m3_year: isNaN(parseFloat(savingsForm.water.utilitySavings)) ? 0 : parseFloat(savingsForm.water.utilitySavings),
            water_unit_cost_m3: isNaN(parseFloat(savingsForm.water.unitCost)) ? 0 : parseFloat(savingsForm.water.unitCost),
            water_kg_co2_m3: isNaN(parseFloat(savingsForm.water.ghgFactor)) ? 0 : parseFloat(savingsForm.water.ghgFactor),
            
            has_electricity: savingsForm.electricity.included,
            electricity_savings_kwh_year: isNaN(parseFloat(savingsForm.electricity.utilitySavings)) ? 0 : parseFloat(savingsForm.electricity.utilitySavings),
            electricity_unit_cost_kwh: isNaN(parseFloat(savingsForm.electricity.unitCost)) ? 0 : parseFloat(savingsForm.electricity.unitCost),
            electricity_kg_co2_kwh: isNaN(parseFloat(savingsForm.electricity.ghgFactor)) ? 0 : parseFloat(savingsForm.electricity.ghgFactor),

            has_gas: savingsForm.gas.included,
            gas_units: savingsForm.gas.gasType ? savingsForm.gas.gasType.units : '',
            gas_savings_units_year: isNaN(parseFloat(savingsForm.gas.utilitySavings)) ? 0 : parseFloat(savingsForm.gas.utilitySavings),
            gas_unit_cost: isNaN(parseFloat(savingsForm.gas.unitCost)) ? 0 : parseFloat(savingsForm.gas.unitCost),
            gas_kg_co2_btu: isNaN(parseFloat(savingsForm.gas.ghgFactor)) ? 0 : parseFloat(savingsForm.gas.ghgFactor),
            gas_type: savingsForm.gas.gasType ? savingsForm.gas.gasType.gas_name : '',
            gas_btu_per_unit: savingsForm.gas.gasType ? savingsForm.gas.gasType.btu_per_unit : 0
        };

        if(props.createModalState === 'edit'){
            try{
                let response = await axiosProtect.put(`/opp/opportunities/edit-opp?opp_id=${props.oppToEdit.id}`, payload);

                props.updateOpportunity(response.data.opportunity);
                props.setCreateModalState('');
    
            }catch(error){
                setSubmissionError('Could not update opportunity. Please contact support if issue persists.');
                console.log(error)
            }       
        }else{
            try{
                let response = await axiosProtect.post('/opp/opportunities/add-opp', payload);
    
                setSubmissionError('');
                props.addOpportunity(response.data.opportunity);
                props.setCreateModalState('');
    
            }catch(error){
                setSubmissionError('Could not create opportunity. Please contact support if issue persists.');
                console.log(error)
            }       
        } 
    }

    const incrementPage = () => {
        if(page === 1 && validateInitPage()){
            setPage(page + 1);

        }else if(page === 2 && validateSavingsPage()){
            setPage(page + 1);

        }else if(page === 3){
            submitOpportunity();
        }
    }

    const populateSavingsField = async (utility, field) => {
        if(savingsForm[utility][`${field}Inherit`]){
            setSavingsForm(prev => ({
                ...prev,
                [utility]: {
                    ...prev[utility],
                    [field]: '',
                    [`${field}Inherit`]: false
                }
            }));

            return;
        }

        let siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

        let queryString = '';
        if(field === 'unitCost'){
            queryString = `${utility === 'electricity' ? 'elec' : utility}_unit_cost`;
        }
        else{
            if(utility === 'water') queryString = 'water_ghg_factor_kg_m3';
            else if(utility === 'electricity') queryString = 'elec_ghg_factor_kg_kwh';
            else if(utility === 'gas') queryString = 'gas_ghg_factor_kg_btu';
        }

        let response = await axiosProtect.get(`/s/sites/get-site-fields?fields=%5B%22${queryString}%22%5D&siteID=${siteID}`);

        let value = response.data.siteData[queryString].toString();

        setSavingsForm(prev => ({
            ...prev,
            [utility]: {
                ...prev[utility],
                [field]: value,
                [`${field}Inherit`]: true
            }
        }));
    }

    const generateDateSpan = () => {
        if(initPageForm.startDateTBD && initPageForm.endDateTBD) return <span>TBD</span>

        let startString = '', endString = '';

        if(initPageForm.startDateTBD) startString = 'TBD';
        else{
            startString = `${initPageForm.startDate['$D']}/${initPageForm.startDate['$m'] + 1}/${initPageForm.startDate['$y']}`;
        }

        if(initPageForm.endDateTBD) endString = 'TBD';
        else{
            endString = `${initPageForm.endDate['$D']}/${initPageForm.endDate['$m'] + 1}/${initPageForm.endDate['$y']}`;
        }

        return <span>{startString} - {endString}</span>;
    }

    const generatePaybackString = () => {
        if(parseInt(initPageForm.projectCost) === 0) return <span>immediate</span>;

        let savings =(savingsForm.water.included ? parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.unitCost) : 0) + 
            (savingsForm.electricity.included ? parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.unitCost) : 0) + 
            (savingsForm.gas.included ? parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.unitCost) : 0);

        let payback = parseFloat(initPageForm.projectCost) / savings;

        return payback < 0 ? <span>N/A</span> : <span>{Number((Math.round(payback * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} years</span>
    }

    return (
        <Modal
            show={props.createModalState !== ''}
            onHide={() => props.setCreateModalState('')}
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <h4 style={{fontSize:'1.2rem', margin:'0'}}>{props.createModalState === 'create' ? 'New Opportunity' : 'Edit Opportunity'}</h4>
            </Modal.Header>

            <Modal.Body>
                {page === 1 &&
                <>
                <TextField
                    label="Title"
                    fullWidth
                    value={initPageForm.title}
                    onChange={e => {
                        if(e.target.value.length > initPageForm.titleMaxLength) return;
                        setInitPageForm({
                            ...initPageForm,
                            title: e.target.value
                        });
                    }}
                    error={initPageForm.errors.titleError !== ''}
                    helperText={
                        `${initPageForm.errors.titleError === '' ? '' : `${initPageForm.errors.titleError}: `}${initPageForm.titleMaxLength - initPageForm.title.length} character${initPageForm.titleMaxLength - initPageForm.title.length === 1 ? '' : 's'} left`
                    }
                />

                <br/>
                <br/>

                <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            label="Category"
                            value={initPageForm.category}
                            onChange={e => {
                                setInitPageForm({
                                    ...initPageForm,
                                    category: e.target.value
                                });
                                
                                let savingsFormCopy = {...savingsForm};
                                for(let key in savingsFormCopy){
                                    savingsFormCopy[key].included = key === e.target.value;
                                }
                                setSavingsForm(savingsFormCopy);
                            }}
                        >
                            <MenuItem value='water'>Water</MenuItem>
                            <MenuItem value='electricity'>Electricity</MenuItem>
                            <MenuItem value='gas'>Gas</MenuItem>
                            <MenuItem value='flw' disabled>FLW</MenuItem>
                        </Select>
                        {initPageForm.errors.categoryError !== '' && <small style={{color:'red'}}>{initPageForm.errors.categoryError}</small>}
                    </FormControl>

                    <div style={{flex:'2'}}></div>

                    <Tooltip title={categoryTip} style={{flex:'14'}}>
                        <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                            <HelpCenter/>
                        </IconButton>
                    </Tooltip>
                </div>

                <br/>

                <div style={{width:'100%'}}>
                    <Autocomplete
                        options={initPageForm.projectLeaders}
                        value={initPageForm.projectLead}
                        fullWidth
                        getOptionLabel={option => `${option.first_name} ${option.last_name}`}
                        onChange={(_, option) => {
                            setInitPageForm({
                                ...initPageForm,
                                projectLead: option
                            })
                        }}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <span>{`${option.first_name} ${option.last_name}`}</span>
                            </Box>
                        )}
                        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps}} label="Project lead" />}
                    />
                    {initPageForm.errors.projectLeadError !== '' && <FormHelperText error>
                        {initPageForm.errors.projectLeadError}
                    </FormHelperText>}
                </div>

                <br/>

                <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                    <TextField
                        label="Project cost ($)"
                        fullWidth
                        value={initPageForm.projectCost}
                        onChange={e => {
                            if(floatRegex.test(e.target.value)){
                                setInitPageForm({
                                    ...initPageForm,
                                    projectCost: e.target.value
                                });
                            }
                        }}
                        error={initPageForm.errors.projectCostError !== ''}
                        helperText={initPageForm.errors.projectCostError}
                    />

                    <div style={{flex:'2'}}></div>

                    <Tooltip title={costTip} style={{flex:'14'}}>
                        <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                            <HelpCenter/>
                        </IconButton>
                    </Tooltip>
                </div>

                <br/>

                <div>
                    <p style={{margin:'0'}}>Implementation start date</p>

                    <div style={{display:'flex', flexDirection:'row'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{flex:'10'}}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    disabled={initPageForm.startDateTBD}
                                    label="Start date"
                                    value={initPageForm.startDate}
                                    onChange={value => {
                                        setInitPageForm({
                                            ...initPageForm,
                                            startDate: value
                                        })
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                        <div style={{flex:'2'}}></div>

                        <FormControlLabel control={<Checkbox checked={initPageForm.startDateTBD} onClick={() => {
                            setInitPageForm({
                                ...initPageForm,
                                startDate: null,
                                startDateTBD: !initPageForm.startDateTBD
                            })
                        }}/>} label="TBD" />
                    </div>
                </div>

                <br/>

                <div>
                    <p style={{margin:'0'}}>Implementation end date</p>

                    <div style={{display:'flex', flexDirection:'row'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{flex:'10'}}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    disabled={initPageForm.endDateTBD}
                                    label="End date"
                                    value={initPageForm.endDate}
                                    onChange={value => {
                                        setInitPageForm({
                                            ...initPageForm,
                                            endDate: value
                                        })
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>

                        <div style={{flex:'2'}}></div>

                        <FormControlLabel control={<Checkbox checked={initPageForm.endDateTBD} onClick={() => {
                            setInitPageForm({
                                ...initPageForm,
                                endDate: null,
                                endDateTBD: !initPageForm.endDateTBD
                            })
                        }}/>} label="TBD" />
                    </div>
                    {
                        initPageForm.errors.dateError !== '' &&
                        <>
                        <br/>
                        <small style={{color:'red'}}>{initPageForm.errors.dateError}</small>
                        </>
                    }
                </div>
                </>
                }

                {
                page === 2 &&
                <>
                <div>
                    <FormControlLabel style={{marginBottom:'1.5%'}} control={<Checkbox checked={savingsForm.water.included} onClick={() => {
                        if(initPageForm.category === 'water') return;
                        setSavingsForm(prev => ({
                            ...prev,
                            water: {
                                ...prev.water,
                                included: !savingsForm.water.included
                            }
                        }));
                    }}/>} label="Water savings included" />

                    <div style={{display:`${savingsForm.water.included ? 'block' : 'none'}`}}>
                        <TextField
                            label="Annual savings (m3/year)"
                            fullWidth
                            style={{marginBottom:'3.25%'}}
                            disabled={!savingsForm.water.included}
                            value={savingsForm.water.utilitySavings}
                            onChange={e => {
                                if(!floatRegex.test(e.target.value)) return;
                                setSavingsForm(prev => ({
                                    ...prev,
                                    water: {
                                        ...prev.water,
                                        utilitySavings: e.target.value
                                    }
                                }));
                            }}
                            error={savingsForm.water.utilitySavingsError !== ''}
                            helperText={savingsForm.water.utilitySavingsError}
                        />

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="Unit cost ($/m3)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                style={{marginBottom:'3.25%', flex:'52'}}
                                disabled={!savingsForm.water.included}
                                value={savingsForm.water.unitCost}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        water: {
                                            ...prev.water,
                                            unitCost: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.water.unitCostError !== ''}
                                helperText={savingsForm.water.unitCostError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.water.included} control={<Checkbox checked={savingsForm.water.unitCostInherit} onClick={() => {
                                populateSavingsField('water', 'unitCost');
                            }}/>} label="Use site's value" />
                        </div>
                        

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="GHG factor (kg CO2/m3)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled={!savingsForm.water.included}
                                style={{marginBottom:'3.25%', flex:'52'}}
                                value={savingsForm.water.ghgFactor}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        water: {
                                            ...prev.water,
                                            ghgFactor: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.water.ghgFactorError !== ''}
                                helperText={savingsForm.water.ghgFactorError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.water.included} control={<Checkbox checked={savingsForm.water.ghgFactorInherit} onClick={() => {
                                populateSavingsField('water', 'ghgFactor');
                            }}/>} label="Use site's value" />
                        </div>
                    </div>
                </div>

                <div>
                    <FormControlLabel style={{marginBottom:'1.5%'}} control={<Checkbox checked={savingsForm.electricity.included} onClick={() => {
                        if(initPageForm.category === 'electricity') return;
                        setSavingsForm(prev => ({
                            ...prev,
                            electricity: {
                                ...prev.electricity,
                                included: !savingsForm.electricity.included
                            }
                        }));
                    }}/>} label="Electricity savings included" />

                    <div style={{display:`${savingsForm.electricity.included ? 'block' : 'none'}`}}>
                        <TextField
                            label="Annual savings (kWh/year)"
                            fullWidth
                            style={{marginBottom:'3.25%'}}
                            disabled={!savingsForm.electricity.included}
                            value={savingsForm.electricity.utilitySavings}
                            onChange={e => {
                                if(!floatRegex.test(e.target.value)) return;
                                setSavingsForm(prev => ({
                                    ...prev,
                                    electricity: {
                                        ...prev.electricity,
                                        utilitySavings: e.target.value
                                    }
                                }));
                            }}
                            error={savingsForm.electricity.utilitySavingsError !== ''}
                            helperText={savingsForm.electricity.utilitySavingsError}
                        />

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="Unit cost ($/kWh)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                style={{marginBottom:'3.25%', flex:'52'}}
                                disabled={!savingsForm.electricity.included}
                                value={savingsForm.electricity.unitCost}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        electricity: {
                                            ...prev.electricity,
                                            unitCost: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.electricity.unitCostError !== ''}
                                helperText={savingsForm.electricity.unitCostError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.electricity.included} control={<Checkbox checked={savingsForm.electricity.unitCostInherit} onClick={() => {
                                populateSavingsField('electricity', 'unitCost');
                            }}/>} label="Use site's value" />
                        </div>
                        

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="GHG factor (kg CO2/kWh)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled={!savingsForm.electricity.included}
                                style={{marginBottom:'3.25%', flex:'52'}}
                                value={savingsForm.electricity.ghgFactor}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        electricity: {
                                            ...prev.electricity,
                                            ghgFactor: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.electricity.ghgFactorError !== ''}
                                helperText={savingsForm.electricity.ghgFactorError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.electricity.included} control={<Checkbox checked={savingsForm.electricity.ghgFactorInherit} onClick={() => {
                                populateSavingsField('electricity', 'ghgFactor');
                            }}/>} label="Use site's value" />
                        </div>
                    </div>
                </div>

                <div>
                    <FormControlLabel style={{marginBottom:'1.5%'}} control={<Checkbox checked={savingsForm.gas.included} onClick={() => {
                        if(initPageForm.category === 'gas') return;
                        setSavingsForm(prev => ({
                            ...prev,
                            gas: {
                                ...prev.gas,
                                included: !savingsForm.gas.included
                            }
                        }));
                    }}/>} label="Gas savings included" />

                    <div style={{display:`${savingsForm.gas.included ? 'block' : 'none'}`}}>
                        <FormControl fullWidth style={{marginBottom:'3.25%'}}>
                            <InputLabel id="demo-simple-select-label">Gas type</InputLabel>
                            <Select
                                label="Gas type"
                                value={savingsForm.gas.gasType?.gas_name}
                                onChange={e => {
                                    for(let i = 0;i<gasTypes.length;++i){
                                        if(gasTypes[i].id == e.target.value){
                                            setSavingsForm(prev => ({
                                                ...prev,
                                                gas: {
                                                    ...prev.gas,
                                                    gasType: gasTypes[i],
                                                }
                                            }));
                                            break;
                                        }
                                    }
                                }}
                            >
                                {gasTypes.map(gt => (
                                    <MenuItem value={`${gt.id}`}>{gt.gas_name}</MenuItem>
                                ))}
                            </Select>
                            {savingsForm.gas.gasTypeError !== '' && <small style={{color:'red'}}>{savingsForm.gas.gasTypeError}</small>}
                        </FormControl>

                        <TextField
                            label={`Annual savings (${savingsForm.gas.gasType ? savingsForm.gas.gasType.units === 'l' ? 'L' : savingsForm.gas.gasType.units : 'gas units'}/year)`}
                            fullWidth
                            style={{marginBottom:'3.25%'}}
                            disabled={!savingsForm.gas.included}
                            value={savingsForm.gas.utilitySavings}
                            onChange={e => {
                                if(!floatRegex.test(e.target.value)) return;
                                setSavingsForm(prev => ({
                                    ...prev,
                                    gas: {
                                        ...prev.gas,
                                        utilitySavings: e.target.value
                                    }
                                }));
                            }}
                            error={savingsForm.gas.utilitySavingsError !== ''}
                            helperText={savingsForm.gas.utilitySavingsError}
                        />

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label={`Unit cost ($/${savingsForm.gas.gasType ? savingsForm.gas.gasType.units === 'l' ? 'L' : savingsForm.gas.gasType.units : 'gas units'})`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                style={{marginBottom:'3.25%', flex:'52'}}
                                disabled={!savingsForm.gas.included}
                                value={savingsForm.gas.unitCost}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        gas: {
                                            ...prev.gas,
                                            unitCost: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.gas.unitCostError !== ''}
                                helperText={savingsForm.gas.unitCostError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.gas.included} control={<Checkbox checked={savingsForm.gas.unitCostInherit} onClick={() => {
                                populateSavingsField('gas', 'unitCost');
                            }}/>} label="Use site's value" />
                        </div>
                        

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label={`GHG factor (kg CO2/BTU)`}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                disabled={!savingsForm.gas.included}
                                style={{marginBottom:'3.25%', flex:'52'}}
                                value={savingsForm.gas.ghgFactor}
                                onChange={e => {
                                    if(!floatRegex.test(e.target.value)) return;
                                    setSavingsForm(prev => ({
                                        ...prev,
                                        gas: {
                                            ...prev.gas,
                                            ghgFactor: e.target.value
                                        }
                                    }));
                                }}
                                error={savingsForm.gas.ghgFactorError !== ''}
                                helperText={savingsForm.gas.ghgFactorError}
                            />

                            <div style={{flex:'4'}}></div>

                            <FormControlLabel style={{flex:'44'}} disabled={!savingsForm.gas.included} control={<Checkbox checked={savingsForm.gas.ghgFactorInherit} onClick={() => {
                                populateSavingsField('gas', 'ghgFactor');
                            }}/>} label="Use site's value" />
                        </div>

                        <small>Make sure site's default gas type matches the selected gas type. If unsure, enter manually.</small>
                    </div>
                </div>
                </>
                }

                {
                page === 3 &&
                <>
                <div>
                    <h4 style={{fontSize:'1.6rem', margin:'0', color:'rgb(8,114,182)'}}>{initPageForm.title}</h4>
                    <small>{initPageForm.category} opportunity</small>
                    <br/>
                    <br/>
                    <p style={{fontSize:'0.95rem'}}>Project lead: <span>{initPageForm.projectLead.first_name.substring(0,1)}. {initPageForm.projectLead.last_name}</span></p>

                    <Table striped bordered size="sm" style={{margin:'0'}}>
                        <thead>
                            <tr>
                                <th style={{textAlign:'center'}} colSpan={5}>Savings breakdown</th>
                            </tr>
                            <tr>
                                <th style={{fontSize:'0.95rem'}}>Resource</th>
                                <th style={{textAlign:'center', fontSize:'0.95rem'}}>Units</th>
                                <th style={{textAlign:'center', fontSize:'0.95rem'}}>Utility savings</th>
                                <th style={{textAlign:'center', fontSize:'0.95rem'}}>Cost savings</th>
                                <th style={{textAlign:'center', fontSize:'0.95rem'}}>GHG savings (MT CO<sub>2</sub>e)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            savingsForm.water.included && 
                            <tr>
                                <td style={{fontSize:'0.95rem'}}>Water</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>m<sup>3</sup></td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round((parseFloat(savingsForm.water.utilitySavings)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`$${
                                    Number((Math.round((parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.unitCost)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round(((parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.ghgFactor)/1000)) * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                            </tr>
                            }

                            {
                            savingsForm.electricity.included && 
                            <tr>
                                <td style={{fontSize:'0.95rem'}}>Electricity</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>kWh</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round((parseFloat(savingsForm.electricity.utilitySavings)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`$${
                                    Number((Math.round((parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.unitCost)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round(((parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.ghgFactor)/1000)) * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                            </tr>
                            }

                            {
                            savingsForm.gas.included && 
                            <tr>
                                <td style={{fontSize:'0.95rem'}}>{savingsForm.gas.gasType?.gas_name.substring(0, 1).toUpperCase() + savingsForm.gas.gasType?.gas_name.substring(1)}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{savingsForm.gas.gasType ? savingsForm.gas.gasType.units === 'l' ? 'L' : <span>m<sup>3</sup></span> : 'gas units'}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round((parseFloat(savingsForm.gas.utilitySavings)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`$${
                                    Number((Math.round((parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.unitCost)) * 100) / 100)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>{`${
                                    Number((Math.round(((parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.gasType?.btu_per_unit) * parseFloat(savingsForm.gas.ghgFactor)/1000)) * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }`}</td>
                            </tr>
                            }

                            <tr>
                                <td style={{fontSize:'0.95rem'}} colSpan={3}>Total</td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>
                                    ${
                                        Number(
                                            (savingsForm.water.included ? (parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.unitCost)) : 0) +
                                            (savingsForm.electricity.included ? (parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.unitCost)) : 0) +
                                            (savingsForm.gas.included ? (parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.unitCost)) : 0)
                                        ).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                </td>
                                <td style={{textAlign:'center', fontSize:'0.95rem'}}>
                                    {
                                        Number(
                                            (savingsForm.water.included ? (parseFloat(savingsForm.water.utilitySavings) * parseFloat(savingsForm.water.ghgFactor)/1000) : 0) +
                                            (savingsForm.electricity.included ? (parseFloat(savingsForm.electricity.utilitySavings) * parseFloat(savingsForm.electricity.ghgFactor)/1000) : 0) +
                                            (savingsForm.gas.included ? (parseFloat(savingsForm.gas.utilitySavings) * parseFloat(savingsForm.gas.gasType?.btu_per_unit) * parseFloat(savingsForm.gas.ghgFactor)/1000) : 0)
                                        ).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <small>*All projections are on an annual basis</small>

                    <br/>
                    <br/>

                    <div>
                        <p style={{margin:'0', fontSize:'0.95rem'}}>Project duration: {generateDateSpan()}</p>
                        <p style={{margin:'0', fontSize:'0.95rem'}}>Capital investment: <span>{`$${Number((Math.round(parseFloat(initPageForm.projectCost) * 100) / 100)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</span></p>
                        <p style={{fontSize:'0.95rem'}}>Payback: {generatePaybackString()}</p>
                    </div>

                    {submissionError !== '' && <p style={{color:'red'}}>{submissionError}</p>}
                </div>
                </>
                }
            </Modal.Body>

            <Modal.Footer>
                <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                    {page > 1 && <IconButton aria-label="site-create-back" onClick={() => setPage(page - 1)}>
                        <ArrowBackIcon />
                    </IconButton>}

                    <div style={{flex:'10'}}></div>

                    <IconButton aria-label="site-create-next" onClick={() => incrementPage()}>
                        {page < 2 ? <ArrowForwardIcon /> : page === 3 ? <DoneIcon /> : <GradingIcon/>}
                    </IconButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateOppModal;